<template>
  <div class="" style="height:100%">
    <!-- calc(100vh - 100px) -->
    <v-row
      :height="$vuetify.breakpoint.mdAndDown ? 'calc(100vh - 100px)' : ''"
      no-gutters
      class="desktop-right-panel hidden-sm-and-down pa-4"
      :class="$vuetify.breakpoint.mdAndDown ? 'mt-2 ml-1' : ''"
    >
      <v-col cols="12" style="height: fit-content;" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0' : 'mx-auto text-center'">
        <p class="right_panel_title">
          <b>{{ $t(`label.importantNotice`) }}</b>
        </p>
        <p class="right_panel_title mb-0">1. {{ $t(`label.rideSidePanTitle1`) }}</p>
        <p class="right_panel_desc mb-0">
          {{ $t(`label.rideSidePanDesc1`) }}
        </p>
        <p class="right_panel_title mb-0">2. {{ $t(`label.rideSidePanTitle2`) }}</p>
        <p class="right_panel_desc mb-0">
          {{ $t(`label.rideSidePanDesc2`) }}
        </p>
        <p class="right_panel_title mb-0">3. {{ $t(`label.rideSidePanTitle3`) }}</p>
        <p class="right_panel_desc mb-0">
          {{ $t(`label.rideSidePanDesc3`) }}
        </p>
        <p class="right_panel_title mb-0">4. {{ $t(`label.rideSidePanTitle4`) }}</p>
        <p class="right_panel_desc mb-0">
          {{ $t(`label.rideSidePanDesc4`) }}
        </p>
        <p class="right_panel_desc">
          {{ $t(`label.rideSidePanDesc5`) }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DevicePlatform } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper } from '@/util'
import AccountBank from '@/assets/svg/profile/bank.svg'
import AccountDeposit from '@/assets/svg/profile/deposit.svg'
import AccountHistory from '@/assets/svg/profile/history.svg'
import AccountInbox from '@/assets/svg/profile/inbox.svg'
import AccountPassword from '@/assets/svg/profile/password.svg'
import AccountProfile from '@/assets/svg/profile/profile.svg'
import AccountReward from '@/assets/svg/profile/reward.svg'
import AccountVip from '@/assets/svg/profile/vip.svg'
import AccountWithdrawal from '@/assets/svg/profile/withdrawal.svg'
import { AFFILIATE_ONE_TIME_BONUS_DETAIL } from '@/store/affiliate.bonus.module'
export default {
  name: 'appBankRightSidePanel',
  components: {},
  data: () => ({
    devicePlatform: DevicePlatform,
    uihelper: uiHelper,
    openNav: false,
    routeName: ROUTE_NAME
  }),
  created() {},
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  watch: {
    isLoggedIn() {
      // this.getAffiliateBonusDetail()
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.right_panel_title {
  height: fit-content;
  font-size: 0.875rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
.right_panel_desc {
  font-size: 0.75rem;
  font-family: 'Roboto', sans-serif;
  line-height: 13px;
  height: fit-content;
  padding-bottom: 15px;
}
.desktop-right-panel {
  overflow-y: auto;
  border-radius: 10px;
  min-height: 700px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

@media (max-width: 1008px) {
  .ptop4 {
    padding-top: 0px !important;
  }
}

@media (max-width: 1008px) {
  .right_panel_title {
    font-size: 0.875rem;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
  .right_panel_desc {
    font-size: 0.75rem;
    font-family: 'Roboto', sans-serif;
    //font-size: 8px;
    line-height: 13px;
  }
}

@media (min-width: 1009px) and (max-width: 1255px) {
  .right_panel_title {
    font-size: 0.875rem;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
  .right_panel_desc {
    //font-size: 9px;
    font-family: 'Roboto', sans-serif;
    line-height: 13px;
  }
}
</style>
