<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'pt-2 pb-4' : 'py-4'" v-if="isHomepage">
    <!--    desktop view-->
    <v-row class="mb-4 hidden-sm-and-down">
      <v-col cols="7" class=" ">
        <v-img src="/static/image/homepage/refer_banner.jpg" style="border-radius: 15px;">
          <v-row no-gutters class="pl-3 pt-3">
            <v-col cols="12" class="d-flex align-center">
              <v-col cols="6" class="pa-0">
                <v-row no-gutters class="headerReferralText white--text">
                  {{ $t(`label.homePageReferralTitle`) }}
                </v-row>
                <v-row no-gutters class="descriptionReferralText white--text">
                  {{ $t(`label.homePageReferralContent`) }}
                </v-row>
                <v-row no-gutters class="pt-3 referral-now-btn">
                  <v-btn class="referNowBtn" @click="goToReferralPage()">
                    {{ $t(`label.referNow`) }}
                  </v-btn>
                </v-row>
              </v-col>
              <!--            <v-col cols="3" class="pa-0 d-flex justify-center align-center">-->
              <!--              <img src="/static/image/homepage/box.png" class="box_img" />-->
              <!--            </v-col>-->
            </v-col>
          </v-row>
        </v-img>
      </v-col>
      <v-col cols="5" class="">
        <!--        <v-img src="/static/image/homepage/office_700_280.jpg" />-->
        <v-img class="" src="/static/image/homepage/bb88_bp_1400_560.jpg" height="100%" @click="goBettingpass()" style="border-radius: 12px;"></v-img>
        <!--        <div class="referral_container" style="height: 100%; width: 95%;">-->
        <!--         -->
        <!--        </div>-->
      </v-col>
    </v-row>

    <!--        testing code for responsive img and its aspect ratio-->

    <!--    <v-row no-gutters class="mb-4 hidden-sm-and-down" style="">-->
    <!--    <v-col cols="7" class="">-->
    <!--      <div class="referral_container" style="height: 100%">left div</div>-->
    <!--      &lt;!&ndash;        <v-img :src="`/static/image/homepage/refer_banner.jpg`" cover></v-img>&ndash;&gt;-->
    <!--    </v-col>-->
    <!--    <v-col cols="5" class="d-flex justify-end">-->
    <!--      <div style="height:100%; width:95%"><v-img :src="`/static/image/homepage/right_section_image.png`" cover></v-img></div>-->

    <!--      &lt;!&ndash;        <div style="height: 100%; width: 95%;" class="referral-right">&ndash;&gt;-->
    <!--      &lt;!&ndash;&lt;!&ndash;          <v-img cover :src="`/static/image/banner/downloadClient/${currency}/bb88_downloadnow_appbanner_desktop.jpg`"  @click="goBettingpass()" style="border-radius: 12px;"></v-img>&ndash;&gt;&ndash;&gt;-->

    <!--      &lt;!&ndash;        </div>&ndash;&gt;-->

    <!--    </v-col>-->
    <!--  </v-row>-->
    <!--    <v-row>-->
    <!--      <v-col cols="7" class="box">-->
    <!--        &lt;!&ndash;        <img src="/static/image/homepage/refer_banner.jpg" />&ndash;&gt;-->
    <!--        <v-img :aspect-ratio="1080 / 763" src="https://source.unsplash.com/OyCl7Y4y0Bk" />-->
    <!--      </v-col>-->
    <!--      <v-col cols="5" class="box">-->
    <!--        &lt;!&ndash;        <img src="/static/image/homepage/refer_banner.jpg" />&ndash;&gt;-->
    <!--        <v-img :aspect-ratio="1080 / 752" src="https://source.unsplash.com/W3FC_bCPw8E" />-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--    <v-row>-->
    <!--      <v-col cols="7" class="box">-->
    <!--        <img src="/static/image/homepage/refer_banner.jpg" />-->
    <!--      </v-col>-->
    <!--      <v-col cols="5" class="box">-->
    <!--        <img src="/static/image/homepage/iot_700_285.jpg" />-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <!--    final test result-->
    <!--    <v-row>-->
    <!--      <v-col cols="7">-->
    <!--        <v-img src="/static/image/homepage/refer_banner.jpg" />-->
    <!--      </v-col>-->
    <!--      <v-col cols="5">-->
    <!--        <v-img src="/static/image/homepage/bb88_bp_1400_560.jpg" />-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--     end of  testing code -->

    <!--    mobile view-->
    <v-row no-gutters class="hidden-md-and-up font-weight-bold" align="end">
      {{ $t(`label.referrals`) }}
    </v-row>
    <v-row no-gutters justify="center" class="mx-0 hidden-md-and-up">
      <v-col cols="12" class="pt-2 referral-banner">
        <!--        <v-img :src="`/static/image/banner/referral/desktop_${currency}_${language}.jpg`" class="hidden-sm-and-down banner-img" @click="goToReferralPage()"></v-img>-->
        <v-img :src="`/static/image/banner/referral/mobile_${currency}_${language}.jpg`" class="hidden-md-and-up banner-img" @click="goToReferralPage()"></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueQrCode from 'vue-qrcode-component'
import stringFormat from 'string-format'
import { formValidator, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { REFERRAL_GET_OVERVIEW } from '@/store/referral.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import { locale } from '@/util/localization-helper'
export default {
  name: 'appHomeReferral',
  data: () => ({
    currency: uiHelper.getCurrency(),
    language: uiHelper.getLanguage(),
    stringFormat: stringFormat,
    routeName: ROUTE_NAME,
    currentCurrency: uiHelper.getCurrency(),
    referralProgress: 0,
    share_url: '',
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  created() {
    this.metaTag()
    this.getReferralOverview()
  },
  components: {
    VueQrCode
  },
  computed: {
    isHomepage() {
      return this.$route.name === ROUTE_NAME.HOME
    },
    webShareApiSupported() {
      return navigator.share
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    memberReferralCode() {
      let d = this.$store.state.member.info.mbrRefferalCode
      this.share_url = uiHelper.getHostname() + '?refer_code=' + d
      return d
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },
    currentMonth() {
      let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      let d = new Date()
      return months[d.getMonth()]
    }
  },
  watch: {
    isLoggedIn() {
      this.getReferralOverview()
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    goToReferralPage() {
      this.$router.push({
        name: ROUTE_NAME.PROFILE_REFERRAL_TIER,
        params: { lang: this.$route.params.lang }
      })
    },
    goBettingpass() {
      this.$router.push({
        name: ROUTE_NAME.VIP,
        params: { lang: this.$route.params.lang }
      })
    },
    getReferralOverview() {
      if (this.isLoggedIn) this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
    },
    toReferral() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_SUMMARY,
        params: { lang: this.$route.params.lang }
      })
    },
    toReferralTier() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_TIER,
        params: { lang: this.$route.params.lang }
      })
    },
    share() {
      const sharing = {
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.memberReferralCode + '.Refer a friend now and earn commission now! ',
        url: this.share_url
      }
      if (this.webShareApiSupported) {
        navigator.share(sharing)
      } else {
        let $f = this
        this.$copyText(this.share_url).then(function(e) {
          $f.alertMessage = 'Referral Link Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    },
    copyMemberCode() {
      // this.$refs.memberReferralCode.select();
      // document.execCommand("copy");
      navigator.clipboard.writeText(this.memberReferralCode)
    }
  }
}
</script>

<style lang="scss">
.referral-now-btn {
  position: absolute;
  bottom: 12%;
}

.box {
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}
.referral-right {
  background-image: url('/static/image/homepage/betting_pass.jpg');
}

.box_img {
  width: 120px;
  height: 120px;
}
.headerReferralText {
  font-size: 20px;
  font-weight: bold;
}
.descriptionReferralText {
  font-size: 14px;
  padding-top: 8px;
}
.referNowBtn {
  background-color: #ffce01 !important;
  border-radius: 32px;
  text-transform: none !important;
  font-weight: bold;
}
.referral_container {
  background-image: url('/static/image/homepage/refer_banner.jpg');
  background-size: 100% 100%;
  width: 100%;
  border-radius: 12px;
  .bettingPassBanner {
    background-image: url('/static/image/homepage/betting_pass.jpg');
    background-size: 100% 100%;
    width: 100%;
    border-radius: 12px;
  }
}
.referral-banner {
  cursor: pointer;
}

.banner-img {
  border-radius: 20px;
}
// .v-card {
//     border-radius: 5px 0 0 5px !important;
// }
.referral-container {
  border-radius: 20px !important;
  .referral-progress-bar {
    // background-color: #EBEBEB;
    border: 2px solid #fddd04;
    border-radius: 5px; // .v-progress-linear__background{
    //     background-color: #EBEBEB !important;
    //     border-color: #EBEBEB !important;
    //     opacity: 1 !important;
    // }
    // .v-progress-linear__determinate{
    //     border-radius: 10px;
    //     background-color: #FDDD04 !important;
    //     border-color: #FDDD04 !important;
    // }
  }
}
.qrCode > img {
  border: 2px solid black;
  padding: 20px;
  margin: 0 auto;
}
.get-coins-chip {
  margin: 0 30px -30px 0;
  z-index: 1;
}
@media (max-width: 959px) {
  .referral-container {
    border-radius: 20px !important;
    font-size: 14px !important;
    p {
      font-size: 14px !important;
    }
    .v-btn.primary-button {
      width: 100% !important;
      height: 30px !important;
      font-size: 12px !important;
    }
  }
  .qrCode > img {
    padding: 10px;
  }
}
</style>
